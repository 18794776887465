import React,{ useState, Fragment, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import UserDetail from './UserDetail'
import Payment from './Payment'
import Price from './Price'
import Constants from '../Constants'
// import UserId from '../Quiz/UserId';
import Instruction from '../Pages/Instruction';
import useProfile from '../Hooks/useProfile'
import useFetch from '../Hooks/useFetch'
import { toast } from 'react-toastify'
import { CssBaseline, Container, Paper, Stepper, Step, StepLabel, Button } from '@material-ui/core';
import Topbar from '../Topbar';
import Footer from '../Footer'

const Dashboard = React.memo(() => {
    
    const history = useHistory();

    const { data: authuser } = useFetch(Constants.url + 'auth/authuser');

    const [Loading, setLoading] = useState(false);

    const { userData, logout, handleProfileChange, updateProfile,
        anchorEl, open, handleMenu, handleClose,
        openmodal, handleOpenModal, handleCloseModal } = useProfile();

    
    // const [price,setPrice] = useState({
    //     amount: ""
    // });
    const [dprice,setDPrice] = useState('');
    // const [checkIds,setCheckIds] = useState('');

    const [activeStep, setActivestep] = useState(1);
    // const labelValues = ['User Details','Pricing', 'Payment'];
    const [steplabel, setSteplabel] = useState([]);

    useEffect(() => {        
        // getUserDetail();
        setSteplabel(['Instructions','Payment']);
        // setLoading(true);
        if(authuser && authuser.status === 'success') {
            
            var discount = authuser.data.price;
            // if(discount === '0') {
            //     // setLoading(false);
            //     history.push('/mcmi');
            // }
            // else {
                // setLoading(false);
                setDPrice(discount)
            // }
        }
       
        /* if(payCheck.check === '1') {
            history.push('/mcmi');
        }
        else {
            // history.push('/');
            setLoading(false);
        }
         */
    }, [authuser,history])        

    //Next Step
    const nextStep = () => {
        if (activeStep === 2) {
            console.log(authuser);
            if(authuser && authuser.status === 'success') {
                var discount = authuser.data.price;
                if(discount === 0) {
                    history.push('/mcmi');
                } else {
                    toast.error('Please Pay the amount');
                }
                // setActivestep(activeStep + 1);
                // else if(dprice.amount === "") {
                //     toast.error('Select Amount');
                //     return false;
                // }
                // sessionStorage.setItem("price", price.amount);
                // setActivestep(activeStep + 1);
            }
        } else {
            setActivestep(activeStep + 1);
        }
    };

    //Previous Step
    const previousStep = () => {
        setActivestep(activeStep - 1);
    };

    // HandleChagen Input
   /*  const handleChange = (e) => {
        setProfileState({
            ...profilestate,
            [e.target.name]: e.target.value
        });
    }

    const handleFileChange = (e) => {
        setProfileState({
            ...profilestate,
            profile_image: e.target.files[0]
        })
    } */

    /*   const handleChangeprice = (e) => {
        setPrice({
            ...price,
            [e.target.name]: e.target.value
        });
    } */

    //Get Steps
    const getStepContent = (step) => {

        switch (step) {
            case 1:
                return (
                    <Instruction
                        title="Instructions"
                        /* handleChange={handleChange}
                        handleFileChange={handleFileChange}
                        values={profilestate} */
                    />
                )
            case 2:
                 return (
                     <Payment
                        // handleChange={handleChangeprice}
                        title="Payment"
                        // values={price}
                        price={dprice}
                     />
                 )
             /* case 3:
                 return (
                     <Payment
                         title="Payment"
                         price={dprice}
                     />
                 ) */
            default:
                return 'Unknown step';
        }
    }

    return (
        <Fragment>
            <CssBaseline />
            <Container maxWidth="lg">
                <Topbar
                    userData={userData}
                    handleMenu={handleMenu}
                    anchorEl={anchorEl}
                    open={open}
                    handleClose={handleClose}
                    handleOpenModal={handleOpenModal}
                    logout={logout}
                    openmodal={openmodal}
                    handleCloseModal={handleCloseModal}
                    updateProfile={updateProfile}
                    handleChange={handleProfileChange}
                />
                <main style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <Paper elevation={2} style={{ padding: "20px" }}>
                        <Stepper activeStep={activeStep - 1} alternativeLabel>
                            {
                                steplabel.map((label) =>
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                )
                            }
                        </Stepper>
                        <Fragment>
                            <div style={{ margin: "0px 30px 30px 30px" }}>
                                {getStepContent(activeStep)}
                            </div>
                            <div>
                                <Fragment>
                                    <Button
                                        variant="contained"
                                        disabled={activeStep === 1}
                                        onClick={previousStep}
                                        style={{ marginRight: "10px" }}
                                    >Previous</Button>
                                     {/* hidden={activeStep ===2} */}
                                    <Button variant="contained" color="primary" onClick={nextStep}>
                                        {activeStep === steplabel.length ? 'Start Quiz' : 'Next'}</Button>
                                    {/* <Button variant="contained" color="primary" onClick={nextStep}>
                                        {activeStep === steplabel.length ? 'Start Quiz' : 'Next'}</Button> */}
                                </Fragment>
                            </div>
                        </Fragment>
                    </Paper>
                    <Footer />
                </main>
            </Container>
            {
                Loading ? <div id="preloader"></div> : ""
            }
        </Fragment>
    )
});

export default Dashboard
