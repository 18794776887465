import React, { Fragment, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Typography, Grid, Radio, RadioGroup, FormControlLabel, FormControl, Button } from '@material-ui/core';
import Questions from '../Questions';
import Constants from '../../Constants'
import useProfile from '../../Hooks/useProfile'
import Quiz from '../Quiz';
import { toast } from 'react-toastify'
import ReactPaginate from 'react-paginate';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import useFetch from '../../Hooks/useFetch';

/* const QuestList = ({listitem,mcmi3Q,handleChange}) => {
       return (
            <Grid container direction="row" spacing={2}  alignItems="center" className="Questions row_base7">
                {
                    listitem&&listitem.map((item, index) => (
                        <Fragment key={index} >
                            <Grid item xs={12} sm={6} md={6} lg={4} style={{ padding: "8px 14px" }}>
                                <p className="mb-0"><span className="me-3 dot">{item.id}.</span>{item.question}</p>
                                <FormControl component="fieldset">
                                    <RadioGroup aria-label={`question${item.id}`} name={`question${item.id}`} value={`${mcmi3Q.ques}${item.id}`} onChange={handleChange}>
                                        <FormControlLabel value='1' control={<Radio />} label='True' />
                                        <FormControlLabel value='0' control={<Radio />} label='False' />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Fragment>
                    ))
                }
            </Grid>
       ) 
} */

const QuizCompo = React.memo(() => {

    const history = useHistory();

    // const [check, setCheck] = useState('');
    // const [val, setVal] = useState('');
    const { data: apianswers } = useFetch(Constants.url + 'quiz/check_answers');
    console.log(apianswers)
    const { loader, showLoader, hideLoader } = useProfile();

    // const [mcmi3Q, setMcmi3Q] = useState({
    //     ques1: "", ques2: "", ques3: "", ques4: "", ques5: "", ques6: "", ques7: "", ques8: "", ques9: "", ques10: "",
    //     ques11: "", ques12: "", ques13: "", ques14: "", ques15: "", ques16: "", ques17: "", ques18: "", ques19: "", ques20: "",
    //     ques21: "", ques22: "", ques23: "", ques24: "", ques25: "", ques26: "", ques27: "",  ques28: "", ques29: "", ques30: "",
    //     ques31: "", ques32: "", ques33: "", ques34: "", ques35: "", ques36: "", ques37: "", ques38: "", ques39: "", ques40: "",
    //     ques41: "", ques42: "", ques43: "", ques44: "", ques45: "", ques46: "", ques47: "",  ques48: "", ques49: "", ques50: "",
    //     ques51: "", ques52: "", ques53: "", ques54: "", ques55: "", ques56: "", ques57: "", ques58: "", ques59: "", ques60: "",
    //     ques61: "", ques62: "", ques63: "", ques64: "", ques65: "", ques66: "", ques67: "", ques68: "", ques69: "", ques70: "", 
    //     ques71: "", ques72: "", ques73: "", ques74: "", ques75: "", ques76: "", ques77: "", ques78: "", ques79: "", ques80: "",
    //     ques81: "", ques82: "", ques83: "", ques84: "", ques85: "", ques86: "", ques87: "", ques88: "", ques89: "", ques90: "", 
    //     ques91: "", ques92: "", ques93: "", ques94: "", ques95: "", ques96: "", ques97: "", ques98: "", ques99: "", ques100: "",
    //     ques101: "", ques102: "", ques103: "", ques104: "", ques105: "", ques106: "", ques107: "", ques108: "", ques109: "", ques110: "", 
    //     ques111: "", ques112: "", ques113: "", ques114: "", ques115: "", ques116: "", ques117: "", ques118: "", ques119: "", ques120: "",
    //     ques121: "", ques122: "", ques123: "", ques124: "", ques125: "", ques126: "", ques127: "", ques128: "", ques129: "", ques130: "",
    //     ques131: "", ques132: "", ques133: "", ques134: "", ques135: "", ques136: "", ques137: "", ques138: "", ques139: "",
    //     ques140: "", ques141: "", ques142: "", ques143: "", ques144: "", ques145: "", ques146: "", ques147: "", ques148: "", ques149: "", ques150: "", 
    //     ques151: "", ques152: "", ques153: "", ques154: "", ques155: "", ques156: "", ques157: "", ques158: "", ques159: "",
    //     ques160: "", ques161: "", ques162: "", ques163: "", ques164: "", ques165: "", ques166: "", ques167: "", ques168: "", ques169: "", ques170: "", 
    //     ques171: "", ques172: "", ques173: "", ques174: "", ques175: ""
    // });

    // HandleChange Input7
    // const handleChange = (e) => {
    //     setMcmi3Q((prevProps) => ({
    //         ...prevProps,
    //         [e.target.name]: e.target.value
    //     }));
    //     setCheck(e.target.name);
    //     setVal(e.target.value);
    //     // console.log(e.target.name);
    // }

    const [selectedAnswers, setSelectedAnswers] = useState({});

    const handleOptionChange = (ques, val) => {
        setSelectedAnswers(prev => ({
            ...prev,
            [ques]: val
        }));
    };

    const submitHandler = async () => {
        var validateCount = Object.keys(selectedAnswers).length;
        if(validateCount == 175) { 
            showLoader();
            const response = await fetch(Constants.url + 'quiz/scores', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${(localStorage.getItem('token'))}`
                },
                body: JSON.stringify(selectedAnswers)
            });
            const result = await response.json();
            // console.log("result:",result);
            if (result.status === 'success') {
                hideLoader()
                history.push('/');
                toast.success(result.message);
            }
            else {
                hideLoader()
                toast.error(result.message);
                return false;
            }
        }
        else {
            toast.error('All Questions Are Required');
        }
    }

    // useEffect(() => {
    //     setMcmi(Questions['MCMI-III'])
    // },[])

    // Pagination 
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    // const itemsPerPage = 12;
    var questitem = Questions['MCMI-III'];
    const itemsPerPage = 35;
    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(questitem.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(questitem.length / itemsPerPage));
      }, [questitem,itemOffset, itemsPerPage]);

    const handlePageClick = async (event) => {
        // window.scrollTo(0, 0);
        const newOffset = event.selected * itemsPerPage % questitem.length;
        // ---Submit Answer---
        var validateCount = Object.keys(selectedAnswers).length;
        console.log("validateCount:",validateCount);
        console.log("newOffset:",newOffset);
        // if(newOffset !== 140) {
            var answer = JSON.parse(sessionStorage.getItem('answers'));
            // var storageAns = Object.keys(answer).length;
            setItemOffset(newOffset);
            // if((validateCount == newOffset || validateCount > newOffset) && validateCount !== 0) {
            if(validateCount == newOffset  && (validateCount !== 0 && newOffset !== 0)) {
                const response = await fetch(Constants.url + 'quiz/scores', {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': `Bearer ${(localStorage.getItem('token'))}`
                    },
                    body: JSON.stringify(selectedAnswers)
                });
                const result = await response.json();
                if (result.status === 'success') {
                    sessionStorage.setItem('answers', JSON.stringify(selectedAnswers));
                }
            }
            else {
                // toast.error('Answer All Questions');
            }
        // }
        // ---Submit Answer---
    };
    // console.log("itemOffset:",itemOffset);
    // console.log("selectedAnswers:",selectedAnswers);
    if(apianswers&&apianswers.data.length>0){
        var apianswer = apianswers.data.answers;
    } else{
        var apianswer = "";
    }
    const setDisabled = (prefix) => {
        // var answer = JSON.parse(sessionStorage.getItem('answers'));
        // console.log("answer[prefix]:",answer[prefix]);
        if(selectedAnswers[prefix] == '0' || selectedAnswers[prefix] == '1') {
            return true;
        }
        else if(apianswer[prefix] == '0' || apianswer[prefix] == '1') { 
            return true;
        }
        else {
            return false;
        }
    }

    const setChecked = (prefix,value) => {
        // var answer = JSON.parse(sessionStorage.getItem('answers'));
        if(selectedAnswers[prefix] === value) {
            return true;
        }
        else if(apianswer[prefix] == value) { 
            return true;
        }
        else {
            return false;
        }
    }
    return (
        <Fragment>
            <div>
                <Typography variant="h6" color="inherit" >MILLON CLINICAL MULTI-AXIAL INVENTORY (MCMI-III)</Typography>
                {/* <Typography variant="h6" color="inherit" style={{ margin: "20px auto", width: "70%" }}>Given below are 66 sentences describing various activities. Each of these have to be marked as either "L" if you Like it or "D" if you don't like it/ dislike it</Typography> */}
                <Grid container direction="row" spacing={2}  alignItems="center" className="Questions row_base7">
                    {
                        currentItems&&currentItems.map((item, index) => (
                            <Fragment key={index} >
                                <Grid item xs={12} sm={6} md={6} lg={4} style={{ padding: "8px 14px" }}>
                                    <p className="mb-0"><span className="me-3 dot">{item.id}.</span>{item.question}</p>
                                    {
                                        currentItems&&item.options.map(option => (
                                            <Fragment key={option.value}>
                                                   <FormControl component="fieldset">
                                                    <RadioGroup aria-label={`ques${item.id}`} name={`ques${item.id}`} value={option.id}
                                                     onChange={() => handleOptionChange(item.prefix, option.value)}>
                                                        <FormControlLabel name={`ques${item.id}`} value={option.id} control={<Radio/>} label={option.label} 
                                                        checked={setChecked(item.prefix,option.value)}
                                                        />
                                                        {/* disabled={setDisabled(item.prefix)} */}
                                                        {/* <FormControlLabel name={`ques${item.id}`} value={option.id} control={<Radio />} label='False' /> */}
                                                    </RadioGroup>
                                                </FormControl>
                                            </Fragment>
                                        
                                        ))
                                    }
                                </Grid>
                            </Fragment>
                        ))
                    }
                </Grid>
                <ReactPaginate
                    nextLabel={<ChevronRight/>}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={<ChevronLeft/>}
                    breakLabel="..."
                    containerClassName="default-pagination"
                    activeLinkClassName="active"
                    renderOnZeroPageCount={null}
                    // disabledLinkClassName='disabled'
                    // pageClassName='disabled'
                    // disableInitialCallback={true}
                />

            </div>
            <div>
                {
                    itemOffset == '140' &&
                    <Fragment>
                        <Button variant="contained" color="primary" onClick={submitHandler}>
                        Submit</Button>
                    </Fragment>
                }
            </div>
            {loader}
        </Fragment>
    )
});

const Mcmi = React.memo(() => {

    return (
        <Quiz QuizCompo={QuizCompo} />
    )
});

export default Mcmi
