import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
// import useLoader from '../Hooks/useLoader'
import { toast } from 'react-toastify'

const useFetch = (url) => {

    const [data, setData] = useState('');
    // const [loader, showLoader, hideLoader] = useLoader();
    const [Loading, setLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    'Accept': "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            const result = await response.json();
            if (result.status === 'success') {
                setLoading(false);
                setData(result);
            }
            else if (result.status === 'error') {
                setLoading(false);
                setData(result);
            }
            if (result.status === 'tokenError') {
                setLoading(false);
                setData('')
                toast.error('Unauthorized Access');
                localStorage.clear();
                history.push('/login');
            }
        }
        getData()

        return () => {
            setData('');
        }
    }, [history,url]);
    

    return {  data, Loading };
}

export default useFetch;