import { React, useState, Fragment } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify';
import styles from './Auth.module.css'
import Constants from '../Constants'
import useAuth from '../Hooks/useAuth'
import useLoader from '../Hooks/useLoader'
import axios from 'axios';

const Auth = () => {

    const { token } = useAuth();
    const [loader, showLoader, hideLoader] = useLoader();
    const history = useHistory();

    //For Login
    const [email, setemail] = useState('');
    const [password, setpassword] = useState('');
    
    axios.defaults.withCredentials = true;
    const handleLoginSubmit =  (e) => {
        e.preventDefault();

        if (!token || sessionStorage.getItem('token') === null) {
            toast.error('Token is Required')
        }
        else if (token !== sessionStorage.getItem('token')) {
            toast.error('Invalid Token')
        }
        else if (!email || !password) {
            toast.error('Enter Email And Password')
        }
        else {
            showLoader();
            const userData = { email, password }
            
            axios.get(Constants.url+'csrf-cookie').then(res => {
                axios.post(Constants.url + 'auth/login', JSON.stringify(userData),
                    {
                        headers : {
                            'Content-type': 'application/json',
                            'Accept': "application/json"
                        }
                    }
                )
                .then(res => {
                    let result = res.data
                    hideLoader();
                    if (result.status === 'success') {
                        toast.success(result.message)
                        localStorage.setItem('token',result.data.token);
                        localStorage.setItem('userData', JSON.stringify({ name: result.data.name, email: result.data.email }))
                        history.push('/');
                    }
                    else {
                        toast.error(result.message)
                    }
                })
                .catch((err) => {
                    hideLoader();
                    toast.error(err.response.data.message);
                })
                // const response =  await fetch(Constants.url + 'auth/login', {
                //     method: 'POST',
                //     headers: {
                //         'Content-type': 'application/json',
                //         'Accept': "application/json"
                //     },
                //     body: JSON.stringify(userData),
                // });
                // const result = await response.json();
                // console.log(result);
                // if (result.status === 'success') {
                //     hideLoader();
                //     toast.success(result.message)
                //     localStorage.setItem('token', JSON.stringify(result.data.token));
                //     localStorage.setItem('userData', JSON.stringify({ name: result.data.name, email: result.data.email }))
                //     history.push('/');
                // }
                // else {
                    
                //     hideLoader();
                //     toast.error(result.message)
                // }
            });
        }
    }

    return (
        <Fragment>
            <div className={styles.authContainer} id="container">
                <div className={styles.signInContainer} id="signUpShow">
                    <form className={styles.form} onSubmit={handleLoginSubmit}>
                        <h1 className={styles.heading}>Login</h1>
                        {/* <div id="getResponse" className={`alert ${classes}`} role="alert">{response}</div> */}
                        <input type="email" name="email" className={`form-control ${styles.authInput}`} placeholder="Email"
                            value={email} onChange={(e) => setemail(e.target.value)} />
                        <input type="password" name="password" className={`form-control ${styles.authInput}`} placeholder="Password"
                            value={password} onChange={(e) => setpassword(e.target.value)} />
                        <button className={styles.authBtn}>Login</button>
                        <input type="hidden" name="token" value={token}></input>
                        <div className="clearfix"></div>
                        <Link to="/register" className={styles.userAcees}>New User? Create An Account!</Link>
                        {/* <Link to="/" className={styles.forgotPass}>Forgot your password ?</Link> */}
                    </form>
                </div>
            </div>
            {loader}
        </Fragment >
    )
}

export default Auth
