import React from 'react'
import {
    AppBar, Toolbar, Button, Typography, IconButton, Menu,
    MenuItem, TextField, Dialog, DialogActions, DialogContent, DialogTitle
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';

const Topbar = ({ userData, handleMenu, anchorEl, open, handleClose, handleOpenModal, logout, openmodal, handleCloseModal, updateProfile, handleChange }) => {
    return (
        <>
            <AppBar position="static">
                <Toolbar variant="dense">
                    <Typography variant="h6" color="inherit" style={{ margin: "auto" }}>
                        Welcome ! {userData.name}
                    </Typography>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                    >
                        <SettingsIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => { handleClose(); handleOpenModal() }}>My account</MenuItem>
                        <MenuItem onClick={() => { handleClose(); logout(); }}>Logout</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Dialog open={openmodal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Update Profile</DialogTitle>
                <DialogContent>
                    <TextField
                        id="name"
                        name="name"
                        label="Name"
                        type="text"
                        defaultValue={userData.name}
                        onChange={handleChange}
                        autoFocus
                        margin="dense"
                        fullWidth
                    />
                    <TextField
                        id="email"
                        name="email"
                        label="Email Address"
                        type="email"
                        defaultValue={userData.email}
                        margin="dense"
                        fullWidth
                        disabled
                    />
                    <TextField
                        id="password"
                        name="password"
                        label="Password"
                        type="password"
                        onChange={handleChange}
                        margin="dense"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={updateProfile} color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Topbar
