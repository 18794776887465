const questions = {
    'MCMI-III' :[
        {
            id:'1',
            prefix:'ques1',
            question:'Lately, my strength seems to be draining out of me, even in the morning.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'2',
            prefix:'ques2',
            question:'I think highly of rules because they are a good guide to follow.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'3',
            prefix:'ques3',
            question:'I enjoy doing so many different things that I can\'t make up my mind what to do first.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'4',
            prefix:'ques4',
            question:'I feel weak and tired much of the time.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'5',
            prefix:'ques5',
            question:'I know I\'m a superior person, so I don\'t care what people think.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'6',
            prefix:'ques6',
            question:'People have never given me enough recognition for the things I\'ve done.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'7',
            prefix:'ques7',
            question:'If my family puts pressure on me, I\'m likely to feel angry and resist doing what they want.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'8',
            prefix:'ques8',
            question:'People make fun of me behind my back, talking about the way I act or look.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'9',
            prefix:'ques9',
            question:'I often criticize people strongly if they annoy me.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'10',
            prefix:'ques10',
            question:'What few feelings I seem to have I rarely show to the outside world.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'11',
            prefix:'ques11',
            question:'I have a hard time keeping my balance when walking.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'12',
            prefix:'ques12',
            question:'I show my feelings easily and quickly.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'13',
            prefix:'ques13',
            question:'My drug habits have often gotten me into a good deal of trouble in the past.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'14',
            prefix:'ques14',
            question:'Sometimes I can be pretty rough and mean in my relations with my family.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'15',
            prefix:'ques15',
            question:'Things that are going well today won\'t last very long.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'16',
            prefix:'ques16',
            question:'I  am a very agreeable and submissive person.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'17',
            prefix:'ques17',
            question:'As a teenager, I got into lots of trouble because of bad school behavior.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'18',
            prefix:'ques18',
            question:'I\'m afraid to get really close to another person because it may end up with my being ridiculed or shamed.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'19',
            prefix:'ques19',
            question:'I seem to choose friends who end up mistreating me.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'20',
            prefix:'ques20',
            question:'I\'ve had sad thoughts much of my life since I was a child.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'21',
            prefix:'ques21',
            question:'I like to flirt with members of the opposite sex.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'22',
            prefix:'ques22',
            question:'I\'m a very erratic person, changing my mind and feelings all the time.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'23',
            prefix:'ques23',
            question:'Drinking alcohol has never caused me any real problems in my work.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'24',
            prefix:'ques24',
            question:'I  began to feel like a failure some years ago.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'25',
            prefix:'ques25',
            question:'I feel guilty much of the time for no reason that I know.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'26',
            prefix:'ques26',
            question:'Other people envy my abilities.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'27',
            prefix:'ques27',
            question:'When I have a choice, I prefer to do things alone.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'28',
            prefix:'ques28',
            question:'I think it\'s necessary to place strict controls on the behavior of members of my family.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'29',
            prefix:'ques29',
            question:'People usually think of me as a reserved and serious-minded person.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'30',
            prefix:'ques30',
            question:'Lately, I have begun to feel like smashing things.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'31',
            prefix:'ques31',
            question:'I think I\'m a special person who deserves special attention from others.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'32',
            prefix:'ques32',
            question:'I am always looking to make new friends and meet new people.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'33',
            prefix:'ques33',
            question:'If someone criticized me for making a mistake, I would quickly point out some of that person\'s mistakes.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'34',
            prefix:'ques34',
            question:'Lately, I have gone all to pieces.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'35',
            prefix:'ques35',
            question:'I often give up doing things because I\'m afraid I won\'t do them well.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'36',
            prefix:'ques36',
            question:'I often let my angry feelings out and then feel terribly guilty about it.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'37', 
            prefix:'ques37',
            question:'I very often lose my ability to feel any sensations in parts of my body.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'38', 
            prefix:'ques38',
            question:'I do what I want without worrying about its effect on others.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'39', 
            prefix:'ques39',
            question:'Taking so-called illegal drugs may be unwise, but in the past I found I needed them.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'40', 
            prefix:'ques40',
            question:'I guess I\'m a fearful and inhibited person.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'41', 
            prefix:'ques41',
            question:'I\'ve done a number of stupid things on impulse that ended up causing me great trouble.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'42', 
            prefix:'ques42',
            question:'I never forgive an insult or forget an embarrassment that someone caused me.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'43', 
            prefix:'ques43',
            question:'I often feel sad or tense right after something good has happened to me.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'44', 
            prefix:'ques44',
            question:'I feel terribly depressed and sad much of the time now.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'45', 
            prefix:'ques45',
            question:'I always try hard to please others, even when I dislike them.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'46', 
            prefix:'ques46',
            question:'I\'ve always had less interest in sex than most people do.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'47', 
            prefix:'ques47',
            question:'I tend to always blame myself when things go wrong.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'48', 
            prefix:'ques48',
            question:'A long time ago, I decided it\'s best to have little to do with people.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'49', 
            prefix:'ques49',
            question:'Since I was a child, I have always had to watch out for people who were trying to cheat me.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'50', 
            prefix:'ques50',
            question:'I strongly resent "big shots" who always think they can do things better than I can.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'51', 
            prefix:'ques51',
            question:'When things get boring, I like to stir up some excitement.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'52', 
            prefix:'ques52',
            question:'I have an alcohol problem that has made difficulties for me and my family.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'53', 
            prefix:'ques53',
            question:'Punishment never stopped me from doing what I wanted.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'54', 
            prefix:'ques54',
            question:'There are many times, when for no reason, I feel very cheerful and full of excitement.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'55', 
            prefix:'ques55',
            question:'In recent weeks I feel worn out for no special reason.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'56', 
            prefix:'ques56',
            question:'For some time now I\'ve been feeling very guilty because I can\'t do things right anymore.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'57', 
            prefix:'ques57',
            question:'I think I am a very sociable and outgoing person.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'58', 
            prefix:'ques58',
            question:'I\'ve become very jumpy in the last few weeks.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'59', 
            prefix:'ques59',
            question:'I keep very close track of my money so I am prepared if a need comes up.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'60', 
            prefix:'ques60',
            question:'I just haven\'t had the luck in life that others have had.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'61', 
            prefix:'ques61',
            question:'Ideas keep turning over and over in my mind and they won\'t go away.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'62', 
            prefix:'ques62',
            question:'I\'ve become quite discouraged and sad about life in the past year or two.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'63', 
            prefix:'ques63',
            question:'Many people have been spying into my private life for years.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'64', 
            prefix:'ques64',
            question:'I don\'t know why, but I sometimes say cruel things just to make others unhappy.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'65', 
            prefix:'ques65',
            question:'I flew across the Atlantic 30 times last year.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'66', 
            prefix:'ques66',
            question:'My habit of abusing drugs has caused me to miss work in the past.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'67', 
            prefix:'ques67',
            question:'I have many ideas that are ahead of the times.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'68', 
            prefix:'ques68',
            question:'Lately, I have to think things over and over again for no good reason.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:' 69', 
            prefix:'ques69',
            question:'I avoid most social situations because I expect people to criticize or reject me.',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'70',
            prefix:'ques70',
            question:"I often think that I don't deserve the good things that happen to me.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'71',
            prefix:'ques71',
            question:"When I'm alone, I often feel the strong presence of someone nearby who can't be seen.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'72',
            prefix:'ques72',
            question:"I feel pretty aimless and don't know where I'm going in life.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'73',
            prefix:'ques73',
            question:"I often allow others to make important decisions for me.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'74',
            prefix:'ques74',
            question:"I can't seem to sleep, and wake up just as tired as when I went to bed.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'75',
            prefix:'ques75',
            question:"Lately, I've been sweating a great deal and feel very tense.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'76',
            prefix:'ques76',
            question:"I keep having strange thoughts that I wish I could get rid of.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'77',
            prefix:'ques77',
            question:"I have a great deal of trouble trying to control an impulse to drink to excess.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'78',
            prefix:'ques78',
            question:"Even when I'm awake, I don't seem to notice people who are near me.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'79',
            prefix:'ques79',
            question:"I am often cross and grouchy.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'80',
            prefix:'ques80',
            question:"It is very easy for me to make many friends.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'81',
            prefix:'ques81',
            question:"I'm ashamed of some of the abuses I suffered when I was young.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'82',
            prefix:'ques82',
            question:"I always make sure that my work is well planned and organized.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'83',
            prefix:'ques83',
            question:"My moods seem to change a great deal from one day to the next.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'84',
            prefix:'ques84',
            question:"I'm too unsure of myself to risk trying something new.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'85',
            prefix:'ques85',
            question:"I don't blame anyone who takes advantage of someone who allows it.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'86',
            prefix:'ques86',
            question:"For some time now I've been feeling sad and blue and can't seem to snap out of it.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'87',
            prefix:'ques87',
            question:"I often get angry with people who do things slowly.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'88',
            prefix:'ques88',
            question:"I never sit on the sidelines when I'm at a party.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'89',
            prefix:'ques89',
            question:"I watch my family closely so I'll know who can and who can't be trusted.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'90',
            prefix:'ques90',
            question:"I sometimes get confused and feel upset when people are kind to me.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'91',
            prefix:'ques91',
            question:"My use of so-called illegal drugs has led to family arguments.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'92',
            prefix:'ques92',
            question:"I'm alone most of the time and I prefer it that way.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'93',
            prefix:'ques93',
            question:"There are members of my family who say I'm selfish and think only of myself.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'94',
            prefix:'ques94',
            question:"People can easily change my ideas, even if I thought my mind was made up.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'95',
            prefix:'ques95',
            question:"I often make people angry by bossing them.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'96',
            prefix:'ques96',
            question:"People have said in the past that I became too interested and too excited about too many things.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'97',
            prefix:'ques97',
            question:'I believe in the saying, "early to bed and early to rise..."',
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'98',
            prefix:'ques98',
            question:"My feelings toward important people in my life often swing from loving them to hating them.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'99',
            prefix:'ques99',
            question:"In social groups I am almost always very self-conscious and tense.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'100',
            prefix:'ques100',
            question:"I guess I'm no different from my parents in becoming somewhat of an alcoholic.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'101',
            prefix:'ques101',
            question:"I guess I don't take many of my family responsibilities as seriously as I should.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'102',
            prefix:'ques102',
            question:"Ever since I was a child, I have been losing touch with the real world.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'103',
            prefix:'ques103',
            question:"Sneaky people often try to get the credit for things I have done or thought of.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'104',
            prefix:'ques104',
            question:"I can't experience much pleasure because I don't feel I deserve it.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'105',
            prefix:'ques105',
            question:"I have little desire for close friendships.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'106',
            prefix:'ques106',
            question:"I've had many periods in my life when I was so cheerful and used up so much energy that I fell into a low mood.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'107',
            prefix:'ques107',
            question:"I have completely lost my appetite and have trouble sleeping most nights.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'108',
            prefix:'ques108',
            question:"I worry a great deal about being left alone and having to take care of myself.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'109',
            prefix:'ques109',
            question:"The memory of a very upsetting experience in my past keeps coming back to haunt my thoughts.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'110',
            prefix:'ques110',
            question:"I was on the front cover of several magazines last year.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'111',
            prefix:'ques111',
            question:"I seem to have lost interest in most things that I used to find pleasurable, such as sex.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'112',
            prefix:'ques112',
            question:"I have been downhearted and sad much of my life since I was quite young.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'113',
            prefix:'ques113',
            question:"I've gotten into trouble with the law a couple of times.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'114',
            prefix:'ques114',
            question:"A good way to avoid mistakes is to have a routine for doing things.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'115',
            prefix:'ques115',
            question:"Other people often blame me for things I didn't do.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'116',
            prefix:'ques116',
            question:"I have had to be really rough with some people to keep them in line.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'117',
            prefix:'ques117',
            question:"People think I sometimes talk about strange or different things than they do.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'118',
            prefix:'ques118',
            question:"There have been times when I couldn't get through the day without some street drugs.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'119',
            prefix:'ques119',
            question:"People are trying to make me believe that I'm crazy.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'120',
            prefix:'ques120',
            question:"I'll do something desperate to prevent a person I love from abandoning me.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'121',
            prefix:'ques121',
            question:"I go on eating binges a couple of times a week.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'122',
            prefix:'ques122',
            question:"I seem to make a mess of good opportunities that come my way.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'123',
            prefix:'ques123',
            question:"I've always had a hard time stopping myself from feeling blue and unhappy.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'124',
            prefix:'ques124',
            question:"When I'm alone and away from home, I often begin to feel tense and panicky.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'125',
            prefix:'ques125',
            question:"People sometimes get annoyed with me because they say I talk too much or too fast for them.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'126',
            prefix:'ques126',
            question:"Most successful people today have been either lucky or dishonest.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'127',
            prefix:'ques127',
            question:"I won't get involved with people unless I'm sure they'll like me.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'128',
            prefix:'ques128',
            question:"I feel deeply depressed for no reason I can figure out.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'129',
            prefix:'ques129',
            question:"Years later I still have nightmares about an event that was a real threat to my life.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'130',
            prefix:'ques130',
            question:"I don't have the energy to concentrate on my everyday responsibilities anymore.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'131',
            prefix:'ques131',
            question:"Drinking alcohol helps when I'm feeling down.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'132',
            prefix:'ques132',
            question:"I hate to think about some of the ways I was abused as a child.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'133',
            prefix:'ques133',
            question:"Even in good times, I've always been afraid that things would soon go bad.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'134',
            prefix:'ques134',
            question:"I sometimes feel crazy-like or unreal when things start to go badly in my life.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'135',
            prefix:'ques135',
            question:"Being alone, without the help of someone close to depend on, really frightens me.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'136',
            prefix:'ques136',
            question:"I know I've spent more money than I should buying illegal drugs.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'137',
            prefix:'ques137',
            question:"I always see to it that my work is finished before taking time out for leisure activities.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'138',
            prefix:'ques138',
            question:"I can tell that people are talking about me when I pass by them.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'139',
            prefix:'ques139',
            question:"I'm very good at making up excuses when I get into trouble.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'140',
            prefix:'ques140',
            question:"I believe I'm being plotted against.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'141',
            prefix:'ques141',
            question:"I feel that most people think poorly of me.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'142',
            prefix:'ques142',
            question:"I frequently feel there's nothing inside me, like I'm empty and hollow.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'143',
            prefix:'ques143',
            question:"I sometimes force myself to vomit after eating.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'144',
            prefix:'ques144',
            question:"I guess I go out of my way to encourage people to admire the things I say or do.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'145',
            prefix:'ques145',
            question:"I spend my life worrying over one thing or another.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'146',
            prefix:'ques146',
            question:"I always wonder what the real reason is when someone is acting especially nice to me.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'147',
            prefix:'ques147',
            question:"There are certain thoughts that keep coming back again and again in my mind.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'148',
            prefix:'ques148',
            question:"Few things in life give me pleasure.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'149',
            prefix:'ques149',
            question:"I feel shaky and have difficulty falling asleep because painful memories of a past event keep running through my mind.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'150',
            prefix:'ques150',
            question:"Looking ahead as each day begins makes me feel terribly depressed.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'151',
            prefix:'ques151',
            question:"I've never been able to shake the feeling that I'm worthless to others.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'152',
            prefix:'ques152',
            question:"I have a drinking problem that I've tried unsuccessfully to end.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'153',
            prefix:'ques153',
            question:"Someone has been trying to control my mind.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'154',
            prefix:'ques154',
            question:"I have tried to commit suicide.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'155',
            prefix:'ques155',
            question:"I'm willing to starve myself to be even thinner than I am.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'156',
            prefix:'ques156',
            question:"I don't understand why some people smile at me.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'157',
            prefix:'ques157',
            question:"I have not seen a car in the last ten years.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'158',
            prefix:'ques158',
            question:"I get very tense with people I don't know well because they may want to harm me.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'159',
            prefix:'ques159',
            question:"Someone would have to be pretty exceptional to understand my special abilities.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'160',
            prefix:'ques160',
            question:"My current life is still upset by flashbacks of something terrible that happened to me.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'161',
            prefix:'ques161',
            question:"I seem to create situations with others in which I get hurt or feel rejected.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'162',
            prefix:'ques162',
            question:"I often get lost in my thoughts and forget what's going on around me.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'163',
            prefix:'ques163',
            question:"People say I'm a thin person, but I feel that my thighs and backside are much too big.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'164',
            prefix:'ques164',
            question:"There are terrible events from my past that come back repeatedly to haunt my thoughts and dreams.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'165',
            prefix:'ques165',
            question:"Other than my family, I have no close friends.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'166',
            prefix:'ques166',
            question:"I act quickly much of the time and don't think things through as I should.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'167',
            prefix:'ques167',
            question:"I take great care to keep my life a private matter so no one can take advantage of me.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'168',
            prefix:'ques168',
            question:"I very often hear things so well that it bothers me.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'169',
            prefix:'ques169',
            question:"I'm always willing to give in to others in a disagreement because I fear their anger or rejection.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'170',
            prefix:'ques170',
            question:"I repeat certain behaviors again and again, sometimes to reduce my anxiety and sometimes to stop something bad from happening.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'171',
            prefix:'ques171',
            question:"I have given serious thought recently to doing away with myself.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'172',
            prefix:'ques172',
            question:"People tell me that I'm a very proper and moral person.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'173',
            prefix:'ques173',
            question:"I still feel terrified when I think of a traumatic experience I had years ago.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'174',
            prefix:'ques174',
            question:"Although I'm afraid to make friendships, I wish I had more than I do.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        },
        {
            id:'175',
            prefix:'ques175',
            question:"There are people who are supposed to be my friends who would like to do me harm.",
            options:[
                {label:'True',value:1},
                {label:'False',value:0}
            ],
        }

    ]
}

export default questions