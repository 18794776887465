import { useState } from 'react';
import FullPageLoader from '../FullPageLoader'

const useLoader = () => {

    const [Loading, setLoading] = useState(false);

    return [
        Loading ? <FullPageLoader /> : null,
        () => setLoading(true), // Show Loader
        () => setLoading(false), // Hide Loader
    ]
}

export default useLoader
