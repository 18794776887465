import React, { Fragment,useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useFetch from '../Hooks/useFetch';
import Constants from '../Constants';
import useProfile from '../Hooks/useProfile'
import { CssBaseline, Container, Paper } from '@material-ui/core';
import Topbar from '../Topbar';
import Footer from '../Footer';
import { toast } from 'react-toastify';

const Quiz = React.memo(({ QuizCompo }) => {
    
    const history = useHistory(); 

    const { data: authuser,Loading } = useFetch(Constants.url + 'auth/authuser');
    // const { data: payCheck,Loading } = useFetch(Constants.url + 'questions/attemp_check/payments');   

    // const checkValidQ = JSON.parse(sessionStorage.getItem('Qpoints'));

    useEffect(() => {
        if(authuser && authuser.status === 'success') {
            var price = authuser.data.price;
            if(price !== 0) {
                history.push('/');
            }
        }
    }, [authuser,history])

    const { userData, logout, handleProfileChange, updateProfile,
        anchorEl, open, handleMenu, handleClose,
        openmodal, handleOpenModal, handleCloseModal } = useProfile();

    return (
        <Fragment>
            <CssBaseline />
            <Container maxWidth="lg">
                <Topbar
                    userData={userData}
                    handleMenu={handleMenu}
                    anchorEl={anchorEl}
                    open={open}
                    handleClose={handleClose}
                    handleOpenModal={handleOpenModal}
                    logout={logout}
                    openmodal={openmodal}
                    handleCloseModal={handleCloseModal}
                    updateProfile={updateProfile}
                    handleChange={handleProfileChange}
                />
                <main style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <Paper elevation={2} style={{ padding: "20px 0px" }}>
                        <Container>
                            {<QuizCompo />}
                        </Container>
                    </Paper>
                    <Footer />
                </main>
            </Container>
            <Fragment>
                {
                    Loading ? <div id="preloader"></div> : ""
                }
            </Fragment>
        </Fragment>
    )
});

export default Quiz
