import { React, Fragment} from 'react';
import Common from './Common';
import Typography from '@material-ui/core/Typography';

// const Mytext = (props) => {

//     return (
//         <Fragment>
//             <Typography variant="h6" color="inherit" style={{ margin: "20px" }}>{props.title}</Typography>
//             <div className="text-center">
//                 <p>1. Finish the quiz in 1 attempt and do not press back button when the quiz is in progress</p>
//                 <p>2. Do not Reload the page when submit a particular question</p>
//                 <p>3. All Qustions Are Compulsory</p>
//             </div>
//         </Fragment>
//     )
// }
const Instruction = (props) => {

    return (
        <Fragment>
            {/* <Common Text={Mytext} /> */}
                <Typography variant="h6" color="inherit" style={{ margin: "20px" }}>{props.title}</Typography>
                <div className="text-center">
                    <p>1. Finish the quiz in 1 attempt and do not press back button when the quiz is in progress</p>
                    <p>2. Do not Reload the page when submit a particular question</p>
                    <p>3. You cannot change the Answer once it submitted</p>
                    <p>4. All Qustions Are Compulsory</p>
                </div>
        </Fragment>
    )
};

export default Instruction;