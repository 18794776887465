import React from 'react'

const FullPageLoader = () => {
    return (
        <React.Fragment>
            <div id="preloader">
            </div>
        </React.Fragment>
    )
}

export default FullPageLoader
