import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import useLoader from '../Hooks/useLoader'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify'
import Constants from '../Constants';

const Payment = React.memo(({ title,price }) => {

    const history = useHistory();

    const [loader, showLoader, hideLoader] = useLoader();
    const [amount, setAmount] = useState({ amount: "" });

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);
        // setAmount({ amount: sessionStorage.getItem('price') });
        setAmount({ amount: price });
    }, []);

    const submitPayment = () => {
        showLoader();
        fetch(Constants.url + 'payment/order', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(amount),
        }).then((resp) => {
            hideLoader();
            resp.json().then((result) => {
                var options = {
                    "key": result.key_id,
                    "amount": result.amount,
                    "currency": result.currency,
                    "name": result.name,
                    "description": "Careeers4Me Quiz Payment",
                    "order_id": result.order_id,
                    "handler": function (response) {
                        razorpay_payments(response.razorpay_payment_id, response.razorpay_order_id, response.razorpay_signature, result.amount);
                    },
                    "prefill": {
                        "name": result.name,
                        "email": result.email,
                    },
                    "notes": {
                        "address": "Razorpay Corporate Office"
                    },
                    "theme": {
                        "color": "#46A3DC"
                    }
                };

                var rzp1 = new window.Razorpay(options);
                rzp1.open();
            });

        })
    }

    const razorpay_payments = (payment_id, order_id, signature, amt) => {
        showLoader();
        var params = {
            razorpay_payment_id: payment_id,
            razorpay_order_id: order_id,
            razorpay_signature: signature,
            amount: amt,
        }
        fetch(Constants.url + 'payment/pay', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(params),
        }).then((res) => {
            res.json().then((result) => {
                hideLoader();
                if (result.status === "success") {
                    sessionStorage.setItem('PayUniqueId', JSON.stringify(result.uid));
                    toast.success(result.msg);
                    history.push('/mcmi');
                }
                else {
                    toast.error(result.msg);
                }
            });
        })
    }

    return (
        <Fragment>
            <Typography variant="h6" color="inherit" style={{ margin: "20px" }}>
                Click On the below Button to Pay {price} Rs.
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Button variant="contained" color="primary" onClick={submitPayment}>
                        Pay
                    </Button>
                </Grid>
            </Grid>
            {loader}
        </Fragment>
    );
});

export default Payment;