import  React,{ Fragment, useEffect,useState } from 'react';
import { useHistory,useLocation } from "react-router-dom";
import { CssBaseline, Container, Paper, Button } from '@material-ui/core';
import useProfile from '../Hooks/useProfile'
import Topbar from '../Topbar';
import Footer from '../Footer';

const Common = React.memo(({ Text }) => {

    const history = useHistory();
    const location = useLocation();
    const [url,setUrl] = useState('');    
    const pagename = location.pathname.split('/')[1];

    useEffect(() => {
        window.scrollTo(0, 0);
        setUrl(pagename)
     }, [pagename]);

    const goBack = () => {
        history.goBack()
        window.scrollTo(0, 0);
    }

    const { userData, logout, handleProfileChange, updateProfile,
        anchorEl, open, handleMenu, handleClose,
        openmodal, handleOpenModal, handleCloseModal } = useProfile();

    return (
        <Fragment>
            <CssBaseline />
            <Container maxWidth="lg">
                <Topbar
                    userData={userData}
                    handleMenu={handleMenu}
                    anchorEl={anchorEl}
                    open={open}
                    handleClose={handleClose}
                    handleOpenModal={handleOpenModal}
                    logout={logout}
                    openmodal={openmodal}
                    handleCloseModal={handleCloseModal}
                    updateProfile={updateProfile}
                    handleChange={handleProfileChange}
                />
                <main style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <Paper elevation={2} style={{ padding: "20px" }}>
                        {
                            (
                                url !== 'quiz_selection' ? (
                                    <Fragment>
                                    <Button className="mb-4" variant="contained" color="primary" onClick={goBack}>BACK</Button>
                                    <Text />
                                    <Button className="mb-4" variant="contained" color="primary" onClick={goBack}>BACK</Button>
                                    </Fragment>
                                ) :
                                <Fragment>
                                <Text />
                                <Button className="mb-4" variant="contained" color="primary" onClick={()=>history.push('/intro')}>Next</Button>
                                </Fragment>
                            )
                        }
                       
                    </Paper>
                    <Footer />
                </main>
            </Container>
        </Fragment >
    );
});

export default Common;