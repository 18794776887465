import { React, Fragment } from 'react';
import { Link } from 'react-router-dom'
import { Paper, Typography } from '@material-ui/core';
const Footer = () => {
    return (
        <Fragment>
            <Paper elevation={2} style={{ marginTop: "20px", padding: "20px" }}>
                <Typography>
                    {/* <Link to="/disclaimer" className="me-3">Disclaimer</Link> */}
                    <Link to="/terms_condition" className="me-3">Terms and Conditions</Link>
                    <Link to="/privacy_policy" className="me-3">Privacy Policy</Link>
                    <Link to="/refund_cancellations">Refunds/Cancellations</Link>
                </Typography>
            </Paper>
        </Fragment>
    );
};

export default Footer;