import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const Protected = ({ component: Component, ...rest }) => (

    <Route
        {...rest}
        render={props => {
            if (localStorage.getItem('token') === null || localStorage.getItem('token') === undefined || (localStorage.getItem('userData')) === null) {
                return <Redirect to="/login" />;
            }
            else {
                return <Component {...props} />;
            }
        }}
    />
);


export default Protected