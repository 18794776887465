import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Disclaimer from './Components/Pages/Disclaimer';
import Terms_condition from './Components/Pages/Terms_condition';
import Privacy from './Components/Pages/Privacy';
import Refund from './Components/Pages/Refund';
import Instruction from './Components/Pages/Instruction';
import Login from './Components/Auth/Login';
import Register from './Components/Auth/Register';
import Dashboard from './Components/Dashboard/Dashboard';
// import Quiz from './Components/Quiz/Quiz';
import Mcmi from './Components/Quiz/Questions/Mcmi';
import Protected from './Components/Protected';
import { ToastContainer, Flip, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#46A3DC',
            contrastText: "#fff"
        }
    }
});

const App = () => {

    return (
        <MuiThemeProvider theme={theme}>
            <Router>
                <div className="app">
                    <Switch>
                        <Protected exact path="/" component={Dashboard} ></Protected>
                        <Protected exact path="/disclaimer" component={Disclaimer} ></Protected>
                        <Protected exact path="/terms_condition" component={Terms_condition} ></Protected>
                        <Protected exact path="/privacy_policy" component={Privacy} ></Protected>
                        <Protected exact path="/refund_cancellations" component={Refund} ></Protected>
                        <Protected exact path="/quiz_selection" component={Instruction} ></Protected>
                        {/* <Protected exact path="/quiz" component={Quiz} ></Protected> */}
                        <Protected exact path="/mcmi" component={Mcmi} ></Protected>
                        <Route path="/login" component={Login} ></Route>
                        <Route path="/register" component={Register} ></Route>
        
                    </Switch>
                    <ToastContainer
                        position="top-center"
                        transition={Flip}
                        autoClose={10000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss={false}
                        draggable
                        pauseOnHover />
                </div>
            </Router>
        </MuiThemeProvider>
    );
}

export default App;
