import { React, Fragment } from 'react';
import Common from './Common';

const Mytext = () => {
    return (
        <Fragment>
            <div className="footer_pages">
                <h4>Clients Policies for CAREERS4ME</h4>
                <h5>General Booking Policy:</h5>
                <ul>
                    <li>Certain Tests / Counselling Sessions may have different guidelines for specific times during the year. Please abide by all laws and guidelines as applicable.</li>
                    <li>Policies are booking specific and would be informed to the Clients at the time of booking.</li>
                    <li>Reference to CAREERS4ME includes its affiliates, employees and officers. “Service” refers to the Tests or Counselling in which you have made a valid booking through CAREERS4ME.</li>
                </ul>
                <h5>Cancellation Policy:</h5>
                <ul>
                    <li>You can cancel your booking using the CAREERS4ME website or mobile app.</li>
                    <li>The applicable refund amount will be credited to you within 7-14 working days. CAREERS4ME reserves the right to debit from CAREERS4ME Money account, in case of cancellation amount being higher than money already paid by you.</li>
                    <li>Some Services do not accept bookings from Certain Clients based on Age, Gender, Profession, etc. This information is available to the Clients prior to making the booking. For any cancellations associated with such bookings that are dishonoured by the Service, CAREERS4ME shall be under no obligation to refund any amount to the Clients.</li>
                    <li>Services reserve the right to deny check-in where customers are unable to provide a valid government id or if the Service is suspicious of the Clients check-in at its Property. Under all such cases, CAREERS4ME shall be under no obligation to refund any amount to the Clients.</li>
                    <li>For corporate bookings, the cancellation policy mentioned on your contract will apply.</li>
                    <li>In the case of Tests, a 100% refund is liable only up to the time of appearance of the first test Question on the screen. Any cancellation post this, CAREERS4ME shall be under no obligation to refund any amount to the Clients.</li>                
                </ul>
                <ul>
                    <li>In case Consultation Service details page, the following cancellation policy shall apply:</li>
                </ul>
                <h5>A. For bookings up In advance:</h5>
                <table className="table table-bordered table-striped">
                    <tbody>
                        <tr>
                            <td><strong>Cancellation Time</strong></td>
                            <td><strong>Cancellation Fee</strong></td>
                        </tr>
                        <tr>
                            <td>Until 5 days from the Consultation date</td>
                            <td>Free cancellation (100% refund)</td>
                        </tr>
                        <tr>
                            <td>Between 5 days to 48 hours from the Consultation time</td>
                            <td>Partial cancellation (70% refund)</td>
                        </tr>
                        <tr>
                            <td>Between 48 hours to 24 hours from the Consultation time</td>
                            <td>Partial cancellation (20% refund)</td>
                        </tr>
                        <tr>
                            <td>Within 24 hours from the Consultation time</td>
                            <td>No Refund</td>
                        </tr>
                    </tbody>
                </table>
                <ul>
                    <li><strong>No Show:</strong> In case you do not show up at the Service, charges for the Service shall be deducted.</li>
                    <li><strong>Short Services:</strong> In case you decide to shorten your duration any time post Check-In, you will be charged for the entire Service.</li>
                </ul>
                <h5>B. For Spot Bookings</h5>
                <ul>
                    <li>No Refunds to be initiated</li>
                    <li><strong>No Show:</strong> In case you do not show up at the Service, charges for the Service shall be deducted.</li>
                    <li><strong>Short Services:</strong> In case you decide to shorten your duration any time post Check-In, you will be charged for the entire Service.</li>
                </ul>
            </div>
        </Fragment>
    )
}
const Refund = () => {

    return (
        <Fragment>
            <Common Text={Mytext} />
        </Fragment>
    )
};

export default Refund;